import React from "react";

import Footer from "./components/footer";
import Wallet from "./components/wallet";

import "./global.css";

const App = () => {
  return (
    <div>
      <br />
      <br />
      <Wallet />
      <Footer />
    </div>
  );
};

export default App;
